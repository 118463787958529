import React, { useState, useEffect, useContext } from 'react';
import { AddBox } from '@mui/icons-material';

import ModalAddEditUser from './components/ModalAddEditUser';
import ModalAsignarGrupo from './components/ModalAsignarGrupo';

import { ModalGeneral, Tabla } from 'ui-component';
import headers from './components/headers';
import LayautContext from 'Context/ContextLayaut';
import AvisoConfirmacion from 'ui-component/AvisoConfirmacion';
import { ServiciosCurso, ModalCostosGrupo } from './components';

const Curso = ({ permisos, onCambioNivel, ...props }) => {
    //
    const context = useContext(LayautContext);
    const serviciosCursos = ServiciosCurso();

    const [curso, setCurso] = useState([]);
    const [ctl, setCtl] = useState({ estatusCurso: [] });
    const [confirm, setConfirm] = useState({ open: false });
    const [modal, setModal] = useState({ open: false, titulo: '' });
    const [modalAsignarGrupo, setModalAsignarGrupo] = useState({ open: false, titulo: '', row: {} });

    // Handle
    const actualizarData = (nuevaCurso = []) => {
        if (nuevaCurso) {
            let cursoFormato = nuevaCurso.map((cursoObj) => {
                let areasCurso = cursoObj.areas?.split(',') || [];
                return {
                    ...cursoObj,
                    areas: ctl.areas?.filter((area) => areasCurso.includes(area.idarea))
                };
            });
            nuevaCurso = cursoFormato;
        }
        const datausuario = curso.map((oc) => oc.idCurso);
        nuevaCurso.forEach((usuarioI) => {
            const indice = datausuario.indexOf(usuarioI.idCurso);
            if (indice >= 0) curso.splice(indice, 1, usuarioI);
            else curso.splice(0, 0, usuarioI);
        });
        setCurso([...curso]);
    };
    const abrirEditarCurso = (row) => {
        row = {
            ...row,
            estatusCurso: {
                idEstatus: row.idCursoEstatus,
                nCursoEstatus: row.nCursoEstatus
            },
            extemporaneo: row.extemporaneo === 'Si' ? '1' : '0',
            interno: row.interno === 'Si' ? '1' : '0',
            obligatorio: row.obligatorio === 'Si' ? '1' : '0',
            puesto: row.npuesto ? { idpuesto: row.idpuesto, npuesto: row.npuesto } : null
        };
        context.formulario.abrir({
            open: true,
            propsForm: { data: row, ctl: ctl, userInfo: props.usuario },
            formulario: ModalAddEditUser,
            aceptar: (dataForm) => {
                const dataFormAuxiliar = { ...dataForm };
                dataFormAuxiliar.idCursoEstatus = dataFormAuxiliar.estatusCurso.idEstatus;
                dataFormAuxiliar.areas = JSON.stringify(dataFormAuxiliar.areas);
                const formData = new FormData();
                formData.append('idCurso', row.idCurso);
                Object.keys(dataFormAuxiliar).forEach((key) => {
                    if (key !== 'portadaCurso') formData.append(key, dataFormAuxiliar[key]);
                });
                formData.append('portadaCurso', dataFormAuxiliar.portadaCurso?.file);

                serviciosCursos.updateCurso((res) => {
                    actualizarData(res.curso);
                    context.formulario.cerrar();
                }, formData);
            }
        });
    };
    const abrirAsignarGrupo = (row) => {
        context.formulario.abrir({
            open: true,
            titulo: 'Asignar grupos.',
            width: 'sm',
            propsForm: { data: row, ctl: ctl },
            formulario: ModalAsignarGrupo,
            aceptar: (formulario) => {
                if (!formulario.grupoSelected) {
                    context.mensaje({ open: true, status: -1, value: 'No hay ningún grupo seleccionado.' });
                    return;
                }
                serviciosCursos.agregarGrupoCurso(
                    (res) => {
                        context.formulario.cerrar();
                    },
                    { curso: row, formulario: formulario }
                );
            }
        });
        // setModalAsignarGrupo({ open: true, titulo: 'Selecciona los grupos para el curso.', row: row });
    };
    const abrirNuevoCurso = () => () => {
        context.formulario.abrir({
            open: true,
            width: 'sm',
            propsForm: { data: null, ctl: ctl, userInfo: props.usuario },
            formulario: ModalAddEditUser,
            aceptar: (dataForm) => {
                const dataFormAuxiliar = { ...dataForm };
                dataFormAuxiliar.idCursoEstatus = dataFormAuxiliar.estatusCurso.idEstatus;
                dataFormAuxiliar.areas = JSON.stringify(dataFormAuxiliar.areas);
                const formData = new FormData();
                Object.keys(dataFormAuxiliar).forEach((key) => {
                    if ((key !== 'portadaCurso', dataFormAuxiliar)) formData.append(key, dataFormAuxiliar[key]);
                });
                formData.append('portadaCurso', dataFormAuxiliar.portadaCurso?.file);
                serviciosCursos.insertCurso((res) => {
                    actualizarData(res.curso);
                    context.formulario.cerrar();
                }, formData);
            }
        });
    };
    const abrirConfirmarCambio = (usuario) => (e) => {
        let texto = `¿Estás seguro de volver a activar al usuario ${usuario.usuario}?`;
        let status = 1;
        if (usuario.idestatus === '1') {
            texto = `¿Estás seguro de desactivar al usuario ${usuario.usuario}, un usuario inactivo no puede ingresar al sistema?`;
            status = 0;
        }
        setConfirm({
            open: true,
            text: texto,
            aceptar: () =>
                serviciosCursos.cambiarEstatusCurso(
                    (nuevaData) => {
                        actualizarData(nuevaData.usuarios);
                        setConfirm({ open: false });
                    },
                    { idUser: usuario.idCurso, idestatus: status }
                )
        });
    };
    const abrirModalCurso = (curso) => {
        serviciosCursos.getCursoCosto(
            (res) => setModal({ open: true, titulo: `Costos del curso :${curso.titulo}`, curso: { ...curso, costo: res.cursoCosto } }),
            { idCurso: curso.idCurso }
        );
    };
    const esAsignableAGrupo = (cursoItem) => {
        let esAsignable = false;
        cursoItem.areas?.forEach((area) => {
            if (['VENTAS', 'OOAM'].includes(area.narea.toUpperCase())) esAsignable = true;
        });

        return esAsignable;
    };
    const cerrarModalCurso = () => setModal({ ...curso, open: false });
    const cerrarModalAsignarGrupo = () => setModalAsignarGrupo({ open: false, titulo: '', row: {} });
    // Config
    const acciones = [{ label: 'Nuevo Curso', icon: <AddBox />, click: abrirNuevoCurso, disabled: !permisos.crear }];
    const accionesFila = [
        { label: 'Editar', onClick: abrirEditarCurso, enabled: permisos.editar },
        { label: 'Asignar grupo', onClick: abrirAsignarGrupo, enabled: props?.esVistaTrainer ? false : esAsignableAGrupo },
        { label: 'Costos del curso', onClick: abrirModalCurso, enabled: props?.esVistaTrainer ? false : true },
        {
            label: 'Sesiones',
            onClick: (cursoSeleccionado) => onCambioNivel(cursoSeleccionado, curso),
            enabled: true
        }
    ];
    useEffect(() => {
        serviciosCursos.cursosInt((ct) => {
            if (ct.curso) {
                let cursoFormato = ct.curso?.map((cursoObj) => {
                    let areasCurso = cursoObj.areas?.split(',') || [];
                    return {
                        ...cursoObj,
                        areas: ct.areas?.filter((area) => areasCurso.includes(area.idarea))
                    };
                });
                if (!['1'].includes(props.usuario.idrol))
                    cursoFormato = cursoFormato.filter((curso) => {
                        let usuarioTieneArea = false;
                        let idAreasUsuario = props?.usuario?.areas?.map((area) => area.idarea) || [];
                        curso.areas.forEach((area) => {
                            if (idAreasUsuario.includes(area.idarea)) usuarioTieneArea = true;
                        });
                        return usuarioTieneArea;
                    });
                setCurso(cursoFormato || []);
            }
            setCtl({ ...ctl, estatusCurso: ct.estatusCurso || [], areas: ct.areas });
        });
    }, []);
    return (
        <>
            <Tabla
                rows={curso}
                columns={
                    props?.esVistaTrainer
                        ? headers(abrirConfirmarCambio, ctl, permisos).cursoTrainer
                        : headers(abrirConfirmarCambio, ctl, permisos).curso
                }
                accionesTabla={acciones}
                accionesFila={accionesFila}
                columnKey="idCurso"
            />
            <AvisoConfirmacion
                open={confirm.open}
                title="Advertencia"
                text={confirm.text}
                accept={confirm.aceptar}
                cancel={() => setConfirm({ open: false })}
            />
            <ModalGeneral open={modal.open} width="lg" titulo={modal.titulo} OnCerrar={cerrarModalCurso}>
                <ModalCostosGrupo curso={modal.curso} />
            </ModalGeneral>
        </>
    );
};

export default Curso;
