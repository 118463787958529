/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

export default function themePalette(theme) {
    return {
        mode: theme?.customization?.navType,
        common: {
            black: theme.colors?.paper
        },
        primary: {
            light: theme.colors?.primaryLight,
            main: theme.colors?.primaryMain,
            dark: theme.colors?.primaryDark,
            200: theme.colors?.primary200,
            800: theme.colors?.primary800
        },
        secondary: {
            light: theme.colors?.secondaryLight,
            main: theme.colors?.secondaryMain,
            dark: theme.colors?.secondaryDark,
            200: theme.colors?.secondary200,
            800: theme.colors?.secondary800
        },
        error: {
            light: theme.colors?.errorLight,
            main: theme.colors?.errorMain,
            dark: theme.colors?.errorDark
        },
        orange: {
            light: theme.colors?.orangeLight,
            main: theme.colors?.orangeMain,
            dark: theme.colors?.orangeDark
        },
        red: {
            light: theme.colors?.redLight,
            main: theme.colors?.redMain,
            dark: theme.colors?.redDark
        },
        warning: {
            light: theme.colors?.warningLight,
            main: theme.colors?.warningMain,
            dark: theme.colors?.warningDark
        },
        success: {
            light: theme.colors?.successLight,
            200: theme.colors?.success200,
            main: theme.colors?.successMain,
            dark: theme.colors?.successDark
        },
        grey: {
            50: theme.colors?.grey50,
            100: theme.colors?.grey100,
            500: theme.darkTextSecondary,
            600: theme.heading,
            700: theme.darkTextPrimary,
            900: theme.textDark
        },
        dark: {
            light: theme.colors?.darkLight,
            main: theme.colors?.darkMain,
            dark: theme.colors?.darkDark,
            100: theme.colors?.dark100,
            200: theme.colors?.dark200,
            800: theme.colors?.darkBackground,
            900: theme.colors?.darkPaper
        },
        black: {
            light: theme.colors?.blackLight,
            main: theme.colors?.blackMain,
            dark: theme.colors?.blackDark,
            100: theme.colors?.black100,
            200: theme.colors?.black200
        },
        white: {
            main: theme.colors?.whiteMain
        },
        text: {
            primary: theme.darkTextPrimary,
            secondary: theme.darkTextSecondary,
            dark: theme.textDark,
            hint: theme.colors?.grey100
        },
        background: {
            paper: theme.paper,
            default: theme.default,
            default500: theme.colors?.default500
        },
        principal: {
            loginBody: theme.colors?.principalBody,
            loginFooter: theme.colors?.principalFooter
        },
        blue: {
            light: theme.colors?.blueLight,
            main: theme.colors?.blueMain,
            dark: theme.colors?.blueDark
        },
        btn: {
            white: theme.colors?.btnWhite,
            black: theme.colors?.btnBlack,
            green: theme.colors?.btnGreen,
            red: theme.colors?.btnRed,
            blue: theme.colors?.btnblue
        }
    };
}
