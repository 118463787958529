import React, { useState, useEffect } from 'react';
import { AutocompleteForm } from 'ui-component';
import { useFormContext } from 'react-hook-form';
import Services from 'views/trainersMaderas/Components/services';

const NuevoCursoTrainer = ({ alumno }) => {
    const [grupos, setGrupos] = useState([]);
    const {
        register,
        watch,
        setValue,
        formState: { errors },
        control
    } = useFormContext();
    const servicios = Services();
    const cambioValor = (value) => {
        setValue('grupo', value);
    };

    useEffect(() => {
        if (alumno) {
            servicios.buscaCursosTrainerDisponibles(
                (ct) => {
                    setGrupos(ct.cursosDisponibles);
                },
                {
                    // idColaborador: alumno.idColaborador,
                    depto: alumno.id_area,
                    area: alumno.id_subarea,
                    puesto: alumno.id_puesto
                }
            );
        }
    }, []);
    return (
        <>
            <AutocompleteForm
                id="select-grupo"
                label="CURSO"
                name="grupo"
                control={control}
                onChange={cambioValor}
                // onChange={cambioValor(1)}
                rules={{ required: { value: true, message: 'Campo requerido' } }}
                options={grupos}
                optionSelected={{ label: 'titulo', id: 'idAsignacion' }}
                error={errors.departamentos?.message}
            />
        </>
    );
};

export default NuevoCursoTrainer;
