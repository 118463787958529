import React, { useState } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { CloudDownload } from '@mui/icons-material';

const MaterialDescargable = ({ link, title, ...props }) => {
    const descargarArchivo = () => {
        window.open(link, '_blank', 'noreferrer');
    };

    return (
        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <Typography variant="h1" align="center" style={{ color: '#FFFF' }}>
                    Material descargable
                </Typography>
            </Grid>
            <Grid item xs={12} />
            <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudDownload />}
                color="primary"
                onClick={descargarArchivo}
                style={{ alignSelf: 'center' }}
            >
                {title}
            </Button>
        </Grid>
    );
};

export default MaterialDescargable;
