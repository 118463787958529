import React, { useState } from 'react';

import { Grid } from '@mui/material';
import { AvisoConfirmacion, Tabla } from 'ui-component';
import ServiciosUser from 'utils/ServiciosUser';

const UsuarioSedes = ({ usuario, actualizarFrente }) => {
    const [sedes, setSedes] = useState(usuario.sedes || []);
    const [confirm, setConfirm] = useState({ open: false });
    const serviciosUser = ServiciosUser();
    // States
    const [users, setUsers] = useState([]);
    const abrirConfirmarCambio = (sede) => (e) => {
        let texto = `¿Estás seguro de activar la sede ${sede.nom_sede}?`;
        let status = 1;
        if (sede.idestatus == 1) {
            texto = `¿Estás seguro de desactivar la sede ${sede.nom_sede}?`;
            status = 0;
        }
        setConfirm({
            open: true,
            text: texto,
            aceptar: () =>
                serviciosUser.UpdateSedesByusuario(
                    (nuevaData) => {
                        actualizarFrente([
                            {
                                ...usuario,
                                sedes: nuevaData.sedes
                                    .filter((sede) => sede.idestatus == 1)
                                    .map((sede) => sede.nom_sede)
                                    .join()
                            }
                        ]);
                        setSedes(nuevaData.sedes);
                        setConfirm({ open: false });
                    },
                    { idusuario: usuario.idUsuario, ...sede }
                )
        });
    };
    const columns = [
        { label: 'Sede', name: 'nom_sede', filtrar: true },
        {
            label: 'Estatus',
            name: 'idestatus',
            filtrar: false,
            type: { opcion: 'switch', accion: abrirConfirmarCambio }
        }
    ];
    return (
        <Grid container>
            <Grid item xs={12}>
                <Tabla rows={sedes} columns={columns} columnKey="idsede" />
            </Grid>
            <AvisoConfirmacion
                open={confirm.open}
                title="Advertencia"
                text={confirm.text}
                accept={confirm.aceptar}
                cancel={() => setConfirm({ open: false })}
            />
        </Grid>
    );
};

export default UsuarioSedes;
