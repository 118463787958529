import LayautContext from 'Context/ContextLayaut';
import { useContext } from 'react';

const ServiciosCatalogo = () => {
    const context = useContext(LayautContext);

    return {
        // Informacion principal
        getRespuestaActividad: context.llamarServidor('HomeVentas/getRespuestaActividad/'),
        insertRespuestaActividadEntregable: context.llamarServidorRespuesta('Actividad/insertRespuestaActividadEntregable/'),
        updateRespuestaActividadEntregable: context.llamarServidorRespuesta('Actividad/updateRespuestaActividadEntregable/'),
        chunkFile: context.llamarServidorBigFile('ChunksHandler/chunckFile')
    };
};
export default ServiciosCatalogo;
