import React, { useState, useEffect, useRef } from 'react';
import {
    Typography,
    colors,
    DialogContent,
    DialogContentText,
    Grid,
    Divider,
    TextField,
    Slide,
    Button,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@mui/material';
import { useFormContext } from 'react-hook-form';

import AutocompleteForm from 'ui-component/AutocompleteForm';
import { GroupRadioButtonForm, TextFieldNumber } from 'ui-component';

const FormSesion = ({ open, isAdd, data, ctl, setCargando, setMensaje, handleClose, ...props }) => {
    const {
        register,
        watch,
        formState: { errors },
        control
    } = useFormContext();
    return (
        <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
                <TextFieldNumber
                    id="txt-nu-ses"
                    label="Número de sesión*"
                    name="numSesion"
                    error={errors?.numSesion?.message}
                    helperText={errors.numSesion?.message}
                    defaultValue={data?.orden}
                    control={control}
                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                />
            </Grid>
            <Grid item xs={8}>
                <TextField
                    id="txt-tit"
                    label="Título*"
                    size="small"
                    fullWidth
                    {...register('titulo', {
                        required: { value: true, message: 'Campo requerido' },
                        validate: (value) => value.match(/(^\s+|\s+$)/g) == null || 'El inicio o el final no pueden ser espacios'
                    })}
                    error={Boolean(errors.titulo)}
                    helperText={errors.titulo?.message}
                    defaultValue={data?.titulo}
                />
            </Grid>
            <Grid item xs={4}>
                <TextFieldNumber
                    id="txt-num-dur"
                    label="Duración*"
                    name="duracionSesion"
                    error={errors?.duracionSesion?.message}
                    helperText={errors.duracionSesion?.message}
                    defaultValue={data?.duracionSesion}
                    control={control}
                    suffix={` ${watch('duracionSesion')?.split(' ')[0] === '1' ? 'hora' : 'horas'}`}
                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="txt-desc"
                    label="Descripción*"
                    size="small"
                    fullWidth
                    {...register('descripcion', {
                        required: { value: true, message: 'Campo requerido' },
                        validate: (value) => value.match(/(^\s+|\s+$)/g) == null || 'El inicio o el final no pueden ser espacios'
                    })}
                    error={Boolean(errors.descripcion)}
                    helperText={errors.descripcion?.message}
                    defaultValue={data?.descripcion}
                    multiline
                />
            </Grid>
        </Grid>
    );
};

export default FormSesion;
