import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const VisorPDF = ({ data, isBase64, title, ...props }) => {
    const crearArchivoTemporal = () => {
        let link = document.createElement('a');
        // TOMAMOS LA INFORMACION DEL PDF EN BASE64
        const binaryString = atob(data.pdf);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i += 1) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        const blob1 = new Blob([bytes], { type: 'application/pdf' });
        link.href = URL.createObjectURL(blob1);

        return link.href;
    };

    return (
        <Grid container spacing={2}>
            <iframe
                title={title || 'visor PDF'}
                src={isBase64 ? crearArchivoTemporal() : data?.pdf || ''}
                width={'100%'}
                height={'800px'}
            />
        </Grid>
    );
};

export default VisorPDF;
