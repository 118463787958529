import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { StarBorder } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Grid, IconButton, Tooltip, Button, Typography } from '@mui/material';

// import headers from './headers';
import { ModalGeneral, Tabla } from 'ui-component';
import ServiciosCurso from './ServiciosCurso';

const FormDependencia = ({ data, permisos, headers, guardarDependencia, cerrar, ...props }) => {
    const refGuardar = useRef(null);

    // Functions
    const allDataIsSellected = () => {
        const bdDependencia = data.selected.dependencia;
        const filtrarEstatus = data.allData.filter((data) => data.estatus == '1');
        if (!bdDependencia) return filtrarEstatus;

        const dependencias = JSON.parse(bdDependencia);
        const idsActividad = dependencias.map((act) => act.idTemario);
        const newDependencias = filtrarEstatus.map((data) =>
            idsActividad.includes(data.idTemario) ? { ...data, isSeleccionado: true } : data
        );
        return newDependencias;
    };

    const guardar = () => {
        if (refGuardar.current) {
            refGuardar.current.click();
            return true;
        }

        // Si no selecciona ninguno
        guardarDependencia([], data.selected)(null);
        return true;
    };

    const useStyles = makeStyles((theme) => ({
        cancelar: {
            color: theme.palette.btn.red,
            '&:hover': {
                backgroundColor: theme.palette.btn.red,
                color: theme.palette.btn.white
            }
        },
        aceptar: {
            color: theme.palette.btn.green,
            '&:hover': {
                backgroundColor: theme.palette.btn.green,
                color: theme.palette.btn.white
            }
        }
    }));

    const classes = useStyles();

    // Config
    const accionesTabla = [
        {
            label: 'Guardar dependencias',
            icon: <StarBorder />,
            click: (dependenciaSelected) => guardarDependencia(dependenciaSelected, data.selected),
            dataSelected: true,
            ref: refGuardar
        }
    ];

    return (
        <>
            <Grid container>
                <Grid item xs={12} display="flex" justifyContent="center">
                    <Typography variant="h4">
                        {data.selected.orden} {data.selected.titulo}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Tabla
                        rows={allDataIsSellected()}
                        columns={headers}
                        isSeleccionable
                        accionesTabla={accionesTabla}
                        columnKey={data.columnKey}
                    />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="end" sx={{ p: 2 }}>
                    <Button id="btn-cnc" variant="text" onClick={cerrar} className={classes.cancelar}>
                        Cancelar
                    </Button>
                    <Button id="btn-grd" variant="text" className={classes.aceptar} onClick={guardar}>
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

FormDependencia.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
    guardarDependencia: PropTypes.func.isRequired,
    cerrar: PropTypes.func.isRequired
};

export default FormDependencia;
