import LayautContext from 'Context/ContextLayaut';
import { useContext } from 'react';

const ServiciosCatalogo = () => {
    const context = useContext(LayautContext);

    return {
        // Informacion principal
        getInfoInt: context.llamarServidor('IntInicial/getInfoInt/'),
        getInfoInicialAdmin: context.llamarServidor('IntInicial/getInfoInicialAdmin/'),
        getInfoInicialOper: context.llamarServidor('IntInicial/getInfoInicialOper/'),
        getInfoInicialVent: context.llamarServidor('IntInicial/getInfoInicialVent/'),
        // Materiales
        getInfoInicialMaterial: context.llamarServidor('MaterialInduccion/materialInduccionInit/'),
        crearMaterialInduccion: context.llamarServidorRespuesta('MaterialInduccion/createMaterialInduccion/'),
        editarMaterialInduccion: context.llamarServidorRespuesta('MaterialInduccion/updateMaterialInduccion/'),
        actualizarEstatusMaterial: context.llamarServidorRespuesta('MaterialInduccion/cambiarEstatusMaterialInduccion/'),
        //Asignacion de materiales
        getMaterialesAsignados: context.llamarServidor('MaterialInduccion/materialAsignadoInit/'),
        getMaterialesDisponibles: context.llamarServidor('MaterialInduccion/obtenerMaterialesDisponibles/'),
        crearAsignacionMaterial: context.llamarServidorRespuesta('MaterialInduccion/asignarMaterial/'),
        actualizarEstatusMaterialAsignado: context.llamarServidorRespuesta('MaterialInduccion/actualizarEstatusMaterialAsignado/'),
        //Evidencia material asignado
        getEvidenciaMaterial: context.llamarServidor('MaterialInduccion/obtenerEvidenciaMaterial/'),
        subirEvidenciaMaterialAsignado: context.llamarServidorRespuesta('MaterialInduccion/subirEvidenciaMaterialAsignado/'),
        //Calificacion material entregable
        getTrainerCalificacion: context.llamarServidor('Trainer/getTrainerCalificacion'),
        // cursos trainers
        getListaCursosTrainers: context.llamarServidor('Curso/getListaCursosTrainers'),
        buscaCursosTrainerDisponibles: context.llamarServidor('Curso/buscaCursosTrainerDisponibles')
    };
};
export default ServiciosCatalogo;
