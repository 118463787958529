import React, { useEffect, useState, useContext } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Add, Settings, Reorder } from '@mui/icons-material';
import { Grid, Tooltip, IconButton } from '@mui/material';
import { Tabla } from 'ui-component';
import { makeStyles } from '@mui/styles';
import LayautContext from 'Context/ContextLayaut';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

// project imports
import FormVideo from './FormVideo';
import FormOrdenar from 'views/Configuracion/Components/FormOrdenar';
import Services from 'views/Configuracion/Components/Services';
import { BigPlayButton, Player, ControlBar } from 'video-react';
import { gridSpacing } from 'store/constant';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Configuracion = (props) => {
    const { permisos, usuario, panelAlumno, setPanelAlumno, ctls, setCtls } = props;
    const services = Services();
    const context = useContext(LayautContext);
    const theme = useTheme();

    // States

    const agregarVideo = () => () => {
        context.formulario.abrir({
            open: true,
            width: 'sm',
            titulo: `Agregar Video`,
            propsForm: { data: { video: null }, ctls: ctls },
            formulario: FormVideo,
            aceptar: (dataForm) => {
                const formData = new FormData();
                if (Array.isArray(dataForm.video) && dataForm.video[0]) dataForm.video = dataForm.video[0].file;
                formData.append('orden', panelAlumno.videos.length + 1);
                Object.keys(dataForm).map((key) => formData.append(key, dataForm[key]));
                if (dataForm?.video?.size > 1900000) {
                    const nombreOriginal = dataForm.video.path;
                    formData.delete('video');
                    services.chunkFile(
                        (res, uidFolderName) => {
                            formData.append('video', uidFolderName + '/' + nombreOriginal);
                            formData.append('uidFolderName', uidFolderName);
                            services.insertVideo(
                                (res) => {
                                    setPanelAlumno({ ...panelAlumno, videos: res.videos });
                                    let idsDepartamentosExistentes =
                                        res.deptosExistentes?.map((departamento) => departamento.iddepto) || [];
                                    setCtls({ ...ctls, departamentosExistentes: idsDepartamentosExistentes });
                                },
                                formData,
                                { cargando: false, isBigFileResponse: true, uidFolderName: uidFolderName }
                            );
                        },
                        {},
                        dataForm.video
                    );
                    context.formulario.cerrar();
                } else {
                    services.insertVideo(
                        (res) => {
                            setPanelAlumno({ ...panelAlumno, videos: res.videos });
                            let idsDepartamentosExistentes = res.deptosExistentes?.map((departamento) => departamento.iddepto) || [];
                            setCtls({ ...ctls, departamentosExistentes: idsDepartamentosExistentes });
                            context.formulario.cerrar();
                        },
                        formData,
                        {
                            headers: {
                                'content-type': 'multipart/form-data'
                            }
                        }
                    );
                }
            }
        });
    };
    const editarVideo = (video) => {
        context.formulario.abrir({
            open: true,
            width: 'sm',
            titulo: `Editar Video`,
            propsForm: { data: { video: video }, ctls: ctls },
            formulario: FormVideo,
            aceptar: (dataForm) => {
                const formData = new FormData();
                if (Array.isArray(dataForm.video) && dataForm.video[0]) dataForm.video = dataForm.video[0].file;
                Object.keys(dataForm).map((key) => formData.append(key, dataForm[key]));
                formData.append('idConfVideoInforma', video.idConfVideoInforma);
                if (dataForm?.video?.size > 1900000) {
                    const nombreOriginal = dataForm.video.path;
                    formData.delete('video');
                    services.chunkFile(
                        (res, uidFolderName) => {
                            formData.append('video', uidFolderName + '/' + nombreOriginal);
                            formData.append('uidFolderName', uidFolderName);
                            services.updateVideo(
                                (res) => {
                                    setPanelAlumno({ ...panelAlumno, videos: res.videos });
                                    let idsDepartamentosExistentes =
                                        res.deptosExistentes?.map((departamento) => departamento.iddepto) || [];
                                    setCtls({ ...ctls, departamentosExistentes: idsDepartamentosExistentes });
                                },
                                formData,
                                { cargando: false, isBigFileResponse: true, uidFolderName: uidFolderName }
                            );
                        },
                        {},
                        dataForm.video
                    );
                    context.formulario.cerrar();
                } else {
                    services.updateVideo(
                        (res) => {
                            setPanelAlumno({ ...panelAlumno, videos: res.videos });
                            let idsDepartamentosExistentes = res.deptosExistentes?.map((departamento) => departamento.iddepto) || [];
                            setCtls({ ...ctls, departamentosExistentes: idsDepartamentosExistentes });
                            context.formulario.cerrar();
                        },
                        formData,
                        {
                            headers: {
                                'content-type': 'multipart/form-data'
                            }
                        }
                    );
                }
            }
        });
    };
    const eliminarVideo = (video) => {
        context.formulario.cerrar();
        context.modal.confirmar({
            open: true,
            title: '¡ALERTA!',
            text: '¿Está seguro de eliminar el registro?, no podrá recuperarlo más tarde.',
            aceptar: () => {
                services.deleteVideo(
                    (res) => {
                        setPanelAlumno({ ...panelAlumno, videos: res.videos });
                        context.modal.cerrar();
                    },
                    { idConfVideoInforma: video.idConfVideoInforma }
                );
            }
        });
    };

    const acciones = [
        {
            label: 'Agregar video',
            icon: <Add />,
            click: agregarVideo,
            disabled: !permisos?.creaVideoBien
        }
    ];
    const accionesFila = [
        { label: 'Editar', onClick: editarVideo, enabled: permisos?.edVideoBien },
        { label: 'Eliminar', onClick: eliminarVideo, enabled: permisos?.delVideoBien },
        { label: 'Sin opciones', onClick: (row) => {}, enabled: !(permisos?.edVideoBien && permisos?.delVideoBien) }
    ];

    const columnas = [
        { label: 'Título', name: 'titulo', filtrar: true },
        { label: 'Departamento', name: 'ndepartamento', filtrar: true },
        { label: 'F. Creación', name: 'fechaCreacion', filtrar: true },
        { label: 'F. Modificación', name: 'fechaModificacion', filtrar: true },
        { label: 'Creador', name: 'ncrea', filtrar: true }
    ];

    // Components
    const IconTooltip = ({ title, children, onClick, disabled }) => (
        <Tooltip title={title} disableInteractive disabled={disabled}>
            <IconButton onClick={onClick} disabled={disabled}>
                {children}
            </IconButton>
        </Tooltip>
    );

    return (
        <Grid container spacing={gridSpacing}>
            <Tabla
                rows={panelAlumno.videos}
                accionesTabla={acciones}
                columns={columnas}
                accionesFila={accionesFila}
                columnKey="idConfVideoInforma"
            />
        </Grid>
    );
};

export default Configuracion;
