import React, { useState, useEffect } from 'react';
import { School, Group } from '@mui/icons-material';
import { List, ListItem, Grid, Box, Typography, Tooltip, IconButton, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const useStyles = makeStyles((theme) => ({
    curso: {
        backgroundColor: theme.palette.secondary[800]
    },
    grupo: {
        borderRadius: '8px',
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.secondary[800]
    }
}));

const Lectura = ({ permiso, usuario, data, onClickFinalizar }) => {
    const classes = useStyles();
    const lectura = data?.lectura;

    // Functions
    function decodeHTMLEntities(str) {
        const element = document.createElement('div');
        if (str && typeof str === 'string') {
            // strip script/html tags
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = '';
        }

        return str;
    }

    // const finalizar = () => {
    //     onClickFinalizar({
    //         tipo: data.tipo,
    //         idDetalleActividad: data.idDetalleActividad,
    //         idColaborador: data.idColaborador,
    //         idActividad: data.idActividad,
    //         idTemario: data.idTemario,
    //         idSesion: data.idSesion,
    //         idCurso: data.idCurso
    //     });
    // };

    useEffect(() => {}, []);

    return (
        <Grid container spacing={2}>
            {/* <Grid item xs={12} style={{ backgroundColor: '#f5f5f5', marginLeft: '10px', paddingRight: '10px' }}></Grid> */}
            <Grid item>
                <Box>
                    <Typography>{decodeHTMLEntities(lectura.lectura)}</Typography>
                    {/* <div dangerouslySetInnerHTML={{ __html: lectura.lectura }}></div> */}
                </Box>
            </Grid>
            {/* {!data.onlyShow && (
                <Grid item xs={12} display="flex" justifyContent="end">
                    <Button onClick={finalizar}>Terminado</Button>
                </Grid>
            )} */}
        </Grid>
    );
};

export default Lectura;
