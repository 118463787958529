import React from 'react';
import { Divider, FormControlLabel, FormGroup, Grid, Switch, TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { GroupRadioButtonForm, SwitchForm, TextFieldNumber } from 'ui-component';
import NumberFormat from 'react-number-format';

const FormCostoGrupo = ({ open, isAdd, data, ctl, setCargando, setMensaje, handleClose, ...props }) => {
    const {
        register,
        watch,
        formState: { errors },
        control
    } = useFormContext();
    return (
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <TextField
                    id="txt-con"
                    label="Concepto *"
                    size="small"
                    fullWidth
                    {...register('nombre', {
                        required: { value: true, message: 'Campo requerido' },
                        validate: (value) => value.match(/(^\s+|\s+$)/g) == null || 'El inicio o el final no pueden ser espacios'
                    })}
                    error={Boolean(errors.nombre)}
                    helperText={errors.nombre?.message}
                    defaultValue={data?.nombre}
                />
            </Grid>
            <Grid item xs={6} md={4}>
                <TextFieldNumber
                    id="txt-num-cos"
                    label="Costo *"
                    name="costo"
                    error={errors?.costo?.message}
                    helperText={errors.costo?.message}
                    defaultValue={data?.costo}
                    control={control}
                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                    prefix="$"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="txt-obs"
                    label="Observaciones *"
                    size="small"
                    fullWidth
                    {...register('observaciones', {
                        required: { value: true, message: 'Campo requerido' },
                        validate: (value) => value.match(/(^\s+|\s+$)/g) == null || 'El inicio o el final no pueden ser espacios'
                    })}
                    error={Boolean(errors.observaciones)}
                    helperText={errors.observaciones?.message}
                    defaultValue={data?.observaciones}
                    multiline
                />
            </Grid>
            <Grid item xs={12}>
                <Divider textAlign="left">
                    <SwitchForm
                        id="swt-frm-ext"
                        name="costoExtraActivo"
                        control={control}
                        rules={{}}
                        defaultValue={data?.costoExtraActivo}
                        label="Costos Extra"
                    />
                </Divider>
            </Grid>
            {(watch('costoExtraActivo') === undefined ? data?.costoExtraActivo : watch('costoExtraActivo')) && (
                <>
                    <Grid item xs={6} md={4}>
                        <TextFieldNumber
                            id="txt-num-het"
                            label="Horas extra *"
                            name="horaExtra"
                            error={errors?.horaExtra?.message}
                            helperText={errors.horaExtra?.message}
                            defaultValue={data?.horaExtra}
                            control={control}
                            suffix={` ${watch('horaExtra')?.split(' ')[0] === '1' ? 'hora' : 'horas'}`}
                            rules={{ required: { value: true, message: 'Campo requerido' } }}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <TextFieldNumber
                            id="txt-num-ceh"
                            label="Costo Extra por hora *"
                            name="costoPorHora"
                            error={errors?.costoPorHora?.message}
                            helperText={errors.costoPorHora?.message}
                            defaultValue={data?.costoPorHora}
                            control={control}
                            rules={{ required: { value: true, message: 'Campo requerido' } }}
                            prefix="$"
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Typography variant="caption">Costo total extra</Typography>
                        <Typography variant="body1">
                            <NumberFormat
                                id="txt-cos-tot"
                                value={watch('costoPorHora') * watch('horaExtra') || 0}
                                displayType="text"
                                prefix="$"
                                thousandSeparator=","
                                decimalScale={2}
                            />
                        </Typography>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default FormCostoGrupo;
