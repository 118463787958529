import React, { useRef } from 'react';
import { Avatar, Button, IconButton } from '@mui/material';
import { Controller } from 'react-hook-form';

import { Cancel, Edit, Refresh } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    inputFile: {
        display: 'none'
    },

    avatar: {
        border: `4px solid ${theme.palette.white}`,
        height: theme.spacing(20),
        width: '100%',
        position: 'relative'
    },

    div_foto: {
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        color: 'transparent',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        '& .hidden-avatar': {
            display: 'none',
            position: 'absolute',
            backgroundColor: 'rgba(0,0,0,0.50)',
            color: 'white',
            zIndex: '99',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: '4px solid white',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '48px'
        },
        '&:hover .hidden-avatar': {
            display: 'flex'
        }
    }
}));
const FormInputImage = ({ control, name, label, defaultValue, alt }) => {
    const classes = useStyles();
    const refInputFile = useRef(null);
    const cambioImagen = (setValue, valueAnterior, archivo) => {
        if (valueAnterior.objeto) URL.revokeObjectURL(valueAnterior.objeto);

        if (archivo)
            if (String(archivo.type).includes('image/')) setValue({ objeto: URL.createObjectURL(archivo), file: archivo });
            else alert('Formato invalido');
        else alert('No ha seleccionado una imagen');
    };
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={{ objeto: defaultValue.objeto, file: null, descarta: null }}
            render={({ field }) => (
                <>
                    <div className={classes.div_foto}>
                        <Avatar
                            alt="Perfil"
                            src={field.value.objeto ? field.value.objeto : null}
                            className={classes.avatar}
                            variant="square"
                        />
                        <div className="hidden-avatar">
                            <Button
                                color="error"
                                onClick={() => field.onChange({ objeto: defaultValue.objeto, file: null, descarta: null })}
                                variant="contained"
                            >
                                <Refresh />
                            </Button>
                            <Button color="primary" onClick={() => refInputFile.current.click()} variant="contained">
                                <Edit />
                            </Button>
                        </div>
                    </div>
                    <input
                        ref={refInputFile}
                        accept="image/*"
                        className={classes.inputFile}
                        onChange={(e) => cambioImagen(field.onChange, field.value, e.target.files[0] ? e.target.files[0] : null)}
                        onClick={(event) => {
                            event.target.value = null;
                        }}
                        id="contained-button-file"
                        type="file"
                    />
                </>
            )}
        />
    );
};

export default FormInputImage;
