import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Button,
    Collapse,
    Divider,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    Checkbox,
    Switch,
    TextField,
    Typography,
    ListItemText,
    IconButton
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { GroupRadioButtonForm, SwitchForm, TextFieldNumber } from 'ui-component';
import NumberFormat from 'react-number-format';
import { AddBox, Delete } from '@mui/icons-material';
import { TransitionGroup } from 'react-transition-group';
import LayautContext from 'Context/ContextLayaut';

function renderItem({ item, onEliminar, onCheck }) {
    return (
        <ListItemButton dense>
            <ListItemIcon>
                <Checkbox
                    edge="start"
                    onChange={(event) => onCheck(event.target.checked, item)}
                    checked={item.isCorrecta == '1'}
                    color="warning"
                />
            </ListItemIcon>
            <ListItemText primary={item.nrespuesta} />
            <ListItemIcon>
                <IconButton edge="end" aria-label="delete" title="Delete" onClick={() => onEliminar(item)}>
                    <Delete />
                </IconButton>
            </ListItemIcon>
        </ListItemButton>
    );
}

const FormPreguntasActividad = ({ open, isAdd, data, ctl, setCargando, setMensaje, handleClose, ...props }) => {
    const {
        register,
        watch,
        formState: { errors },
        control,
        setValue
    } = useFormContext();
    const [errorTxt, setErrorTxt] = useState({ error: false, texto: '' });
    const txtRef = useRef();
    const layautContext = useContext(LayautContext);
    const agregarOpcion = (opciones, onChange) => (event) => {
        const nuevo = txtRef.current.value?.trim();
        console.log('nuevo', nuevo);
        if (nuevo) {
            setErrorTxt({ error: false, texto: '' });
            const error = opciones.some((opc) => opc.nrespuesta == nuevo);
            if (!error) {
                onChange(
                    [
                        {
                            accion: 'ins',
                            idRespuesta: `nuevo_${txtRef.current.value}`,
                            nrespuesta: txtRef.current.value,
                            isCorrecta: false
                        }
                    ].concat(opciones)
                );
                txtRef.current.value = '';
            } else layautContext.mensaje({ open: true, status: -1, value: 'La respuesta ya fue registrada previamente.' });
        } else {
            setErrorTxt({ error: true, texto: 'Campo requerido' });
            console.log('muestra error');
        }
    };
    const eliminarOpcion = (opciones, onChange) => (opcEliminar) => {
        if (opcEliminar.accion == 'ins') opciones.splice(opciones.map((opc) => opc.idRespuesta).indexOf(opcEliminar.idRespuesta), 1);
        else opciones.find((opc) => opc.idRespuesta == opcEliminar.idRespuesta).accion = 'elm';
        onChange(opciones);
    };
    const checkOpcion = (opciones, onChange) => (checkValue, opcEliminar) => {
        if (opcEliminar.accion != 'ins') opciones.find((opc) => opc.idRespuesta == opcEliminar.idRespuesta).accion = 'upd';
        opciones.find((opc) => opc.idRespuesta == opcEliminar.idRespuesta).isCorrecta = checkValue;
        onChange(opciones);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label="Pregunta*"
                    size="small"
                    fullWidth
                    {...register('npregunta', {
                        required: { value: true, message: 'Campo requerido' },
                        validate: (value) => value.match(/(^\s+|\s+$)/g) == null || 'El inicio o el final no pueden ser espacios'
                    })}
                    error={Boolean(errors.npregunta)}
                    helperText={errors.npregunta?.message}
                    defaultValue={data?.npregunta}
                />
            </Grid>
            <Grid item xs={12}>
                <Divider textAlign="left">
                    <SwitchForm
                        name="idTipoPregunta"
                        control={control}
                        rules={{}}
                        defaultValue={data?.idTipoPregunta}
                        label="Pregunta cerrada"
                    />
                </Divider>
            </Grid>
            {(watch('idTipoPregunta') === undefined ? data?.idTipoPregunta : watch('idTipoPregunta')) && (
                <Controller
                    defaultValue={data?.respuestas ? data.respuestas.map((rsp) => ({ ...rsp })) : []}
                    name="respuestas"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <Grid item xs={12} container spacing={2}>
                                <Grid item xs={10}>
                                    <TextField
                                        id="txt-nva-res"
                                        label="Nueva Respuesta*"
                                        size="small"
                                        fullWidth
                                        inputRef={txtRef}
                                        error={errorTxt.error}
                                        helperText={errorTxt.texto}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        id="btn-agr-opc"
                                        variant="contained"
                                        fullWidth
                                        size="medium"
                                        onClick={agregarOpcion(value, onChange)}
                                    >
                                        <AddBox />
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <List>
                                    <TransitionGroup>
                                        {value
                                            .filter((item) => item.accion != 'elm')
                                            .map((item, index) => (
                                                <Collapse key={index}>
                                                    {renderItem({
                                                        item,
                                                        onEliminar: eliminarOpcion(value, onChange),
                                                        onCheck: checkOpcion(value, onChange)
                                                    })}
                                                </Collapse>
                                            ))}
                                    </TransitionGroup>
                                </List>
                            </Grid>
                        </>
                    )}
                />
            )}
        </Grid>
    );
};

export default FormPreguntasActividad;
