import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { FormControl, FormControlLabel, FormGroup, FormLabel, RadioGroup, Switch } from '@mui/material';

const SwitchForm = ({ control, name, label, defaultValue, rules, children }) => (
    <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue || null}
        render={({ field }) => (
            <FormControl component="fieldset">
                <FormControlLabel {...field} control={<Switch defaultChecked={defaultValue || null} />} label={label} />
            </FormControl>
        )}
    />
);
SwitchForm.propTypes = {
    control: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValue: PropTypes.object,
    rules: PropTypes.object.isRequired,
    children: PropTypes.node
};

SwitchForm.defaultProps = {
    label: '',
    defaultValue: null
};

export default SwitchForm;
