import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, colors, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { CloudUpload, CloudDownload } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import Services from '../services';

const useStyles = makeStyles((theme) => ({
    helperTextError: {
        color: colors.red[500],
        marginLeft: '14px',
        marginRight: '14px',
        marginTop: '4px',
        fontSize: '0.75rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    helperText: {
        color: 'black',
        marginLeft: '0px',
        marginRight: '14px',
        marginTop: '4px',
        fontSize: '1rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    helperText2: {
        color: colors.grey[500],
        marginLeft: '14px',
        marginRight: '14px',
        marginTop: '4px',
        fontSize: '0.75rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    }
}));

const FormEvidencia = (props) => {
    const classes = useStyles();
    const { idpersona, idmaterial } = props;
    const services = Services();
    const [fileSelected, setFileSelected] = useState({
        selected: false,
        name: '',
        formatoValido: 'No valido',
        asDefault: false
    });
    const [dataDefault, setDataDefault] = useState(null);
    const {
        register,
        watch,
        formState: { errors },
        control,
        setValue,
        getValues
    } = useFormContext();

    const validarTamanio = (tamanio) => {
        if (!tamanio) return false;
        return tamanio / 1024 / 1024 < 25;
    };

    const extensionesNoValidas = [
        'application/x-msdownload',
        'application/javascript',
        'text/javascript',
        'application/x-msdos-program',
        'application/x-sh',
        'text/x-sh',
        'application/octet-stream',
        ''
    ];

    const getEvidencia = async () => {
        await services.getEvidenciaMaterial(
            (res) => {
                if (res.evidencia) {
                    setFileSelected({ ...fileSelected, asDefault: true });
                    setDataDefault(res.evidencia);
                }
            },
            { idpersona: idpersona, idmaterial: idmaterial }
        );
    };

    const cargarFunciones = () => {
        getEvidencia();
    };

    useEffect(() => {
        cargarFunciones();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item lg={12} md={12} xs={12}>
                <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<CloudUpload />} color="secondary">
                    {fileSelected.asDefault ? 'Cambiar' : 'Subir'} archivo evidencia *
                    <TextField
                        InputProps={{
                            accept: 'image/*'
                        }}
                        style={{ display: 'none' }}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => {
                            e.target.files[0]
                                ? setFileSelected({
                                      ...fileSelected,
                                      selected: true,
                                      name: e.target.files[0].name
                                  })
                                : setFileSelected({
                                      ...fileSelected,
                                      selected: false,
                                      name: ''
                                  });
                        }}
                        onClick={(event) => {
                            event.target.value = null;
                        }}
                        type="file"
                        {...register('archivoEvidencia', {
                            required: { value: fileSelected.asDefault ? false : true, message: 'Campo requerido' },
                            validate: (value) =>
                                fileSelected.asDefault
                                    ? true
                                    : (!extensionesNoValidas.includes(value[0]?.type) && validarTamanio(value[0]?.size)) ||
                                      'Extensión o tamaño no válido'
                        })}
                        error={errors?.archivoEvidencia?.message}
                    />
                </Button>
                <br></br>
                <helperText className={classes.helperText2}>{'(max. 25 Mb)'}</helperText>
                <br></br>
                <helperText className={fileSelected.selected && !errors?.archivoEvidencia ? classes.helperText2 : classes.helperTextError}>
                    {fileSelected.selected && !errors?.archivoEvidencia && '(Seleccionado: ' + fileSelected.name + ' )'}
                    {errors?.archivoEvidencia?.message}
                </helperText>
            </Grid>
            <hr></hr>
            {fileSelected.asDefault && dataDefault && (
                <>
                    <Grid item lg={12} md={12} xs={12}>
                        <Button
                            id="btn-evid"
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudDownload />}
                            color="primary"
                            onClick={() => window.open(dataDefault?.ruta, '_blank', 'noreferrer')}
                        >
                            Ver archivo evidencia
                        </Button>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        <Typography style={{ fontWeight: 'bold' }}>Subido el: </Typography>
                        <Typography>{dataDefault.fcrea}</Typography>
                        <br></br>
                        <Typography style={{ fontWeight: 'bold' }}>Subido por: </Typography>
                        <Typography>{dataDefault.ncrea}</Typography>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default FormEvidencia;
