import React, { useState, useEffect, useContext } from 'react';
import LayautContext from 'Context/ContextLayaut';
import { makeStyles, styled } from '@mui/styles';
import clsx from 'clsx';
import {
    Box,
    Typography,
    colors,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Grid,
    Slide,
    InputBase,
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Autocomplete,
    Toolbar,
    TextField,
    IconButton,
    Input
} from '@mui/material';
import NumberFormat from 'react-number-format';
import { School, GroupAdd, Add, Person, PersonRemove, AddCircle, RemoveCircle, CloudDone } from '@mui/icons-material';
import { IconSearch } from '@tabler/icons';

import PropTypes from 'prop-types';
import { Tabla } from 'ui-component';

import AgregarGrupoTrainer from './AgregarGrupoTrainer';

import Services from './services';
import headers from './headers';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    // borderRadius: theme.shape.borderRadius,
    borderRadius: '80px',
    backgroundColor: theme.palette.blue.main,
    // '&:hover': {
    //     backgroundColor: alpha(theme.palette.primary.main, 0.25)
    // },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto'
    },
    color: 'white'
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    borderRadius: '50px',
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    color: 'white',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.blue.main
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '0',
        color: 'white',
        [theme.breakpoints.up('sm')]: {
            width: '0',
            '&:focus': {
                width: '20ch'
            }
        }
    }
}));

const useStyles = makeStyles((theme) => ({
    paper: {
        textAlign: 'center'
    },
    cancelar: {
        color: colors.blue[500]
    },
    aceptar: {
        color: colors.green[500]
    },
    remove: {
        color: colors.red[500]
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.light
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto'
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        color: 'white'
    },
    inputRoot: {
        color: 'inherit'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        color: 'white',
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        borderRadius: '10px',
        width: '0',
        '&:focus': {
            width: '20ch'
        }
    },
    activo: {
        width: '20ch'
    },
    iconButton: {
        color: 'white',
        backgroundColor: theme.palette.blue.main,
        borderRadius: '10px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto'
        },
        '&:hover': {
            backgroundColor: theme.palette.blue.light,
            color: 'white'
        }
    }
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// esVistaTrainers = false,
const AgregarColaborador = ({ open, title, data, cancel, updatesetPersonal, opcion, ...props }) => {
    const classes = useStyles();
    const services = Services();
    const context = useContext(LayautContext);
    const [personal, setPersonal] = React.useState([]);
    const [personalOriginal, setPersonalOriginal] = React.useState([]);
    const [gruposTrainer, setGruposTrainer] = React.useState({ grupos: [] });

    const [cursosTrainer, setCursosTrainer] = React.useState({ cursos: [] });
    const [sesionTrainer, setSesionTrainer] = React.useState({ sesion: [] });

    const [dataForm, setDataForm] = React.useState({ grupo: false });
    const [alumnos, setAlumnos] = React.useState({ data: [], allData: [] });
    const [busqueda, setBusqueda] = useState([]);

    const busquedaColaborador = (e) => {
        const bus = e.target.value;
        let filtrado = alumnos?.allData || [];

        if (bus.length > 0) {
            filtrado = alumnos.allData.filter(
                (alumno) => {
                    const persona = alumno.nombre_persona.toUpperCase();
                    const filtroBuqueda = bus.toUpperCase();
                    const filtros = filtroBuqueda.replace(' ', '|');
                    const contieneParcial = new RegExp(filtros).test(persona);
                    return contieneParcial;
                }

                // alumno.nombre_persona.toLowerCase().includes(e.target.value.toLowerCase()) ||
                // alumno.num_empleado.toLowerCase().includes(e.target.value.toLowerCase())
            );
        }

        setAlumnos({ ...alumnos, data: filtrado });
    };

    // Functions
    const cerrar = () => {
        setAlumnos({ data: [], allData: [] });
        setPersonal([]);
        setBusqueda([]);
        setDataForm({ grupo: false });
        setGruposTrainer({ grupos: [] });
        cancel();
    };

    const asignarAlumnoATrainer = (dataSelected) => (event) => {
        const seleccionada = dataSelected.map((select) => ({ ...select, isMovido: 1 }));
        const arrIdSelected = seleccionada.filter((dat) => !dat.isdelete).map((dat) => dat.idcontrato);
        const arrIdAlumnos = alumnos.data.filter((dat) => !dat.isdelete).map((dat) => dat.idcontrato);

        const alumnoYaAgregado = seleccionada.find((alumno) => arrIdAlumnos.includes(alumno.idcontrato));

        if (arrIdSelected.includes(!data.row?.idcontrato))
            return context.mensaje({ open: true, status: 0, value: 'No se puede asignar a si mismo.' });

        if (alumnoYaAgregado) return context.mensaje({ open: true, status: 0, value: 'El alumno ya está agregado.' });

        const getAlumnosTotal = seleccionada.filter((alumno) => !arrIdAlumnos.includes(alumno.idcontrato));

        const getIdsRegistrados = getAlumnosTotal.map((alumno) => alumno.idcontrato);
        const arrPersonal = personal.filter((alumno) => !getIdsRegistrados.includes(alumno.idcontrato));

        setAlumnos({ data: [...alumnos.data, ...getAlumnosTotal], allData: [...alumnos.data, ...getAlumnosTotal] });
        setPersonal(arrPersonal);
        return true;
    };

    const removerAlumno = (person) => {
        let newAlumnos = [];
        if (person.isNuevo == 0) {
            newAlumnos = alumnos.data.map((alumno) =>
                alumno.idcontrato != person.idcontrato ? alumno : { ...alumno, isMovido: 1, isDelete: 1 }
            );
        } else {
            newAlumnos = alumnos.data.filter((alumno) => alumno.idcontrato != person.idcontrato);
            setPersonal([...personal, ...[{ ...person, isDelete: 0, isNuevo: 1, isSeleccionado: false }]]);
        }

        setAlumnos({ data: newAlumnos, allData: newAlumnos });
    };

    const reagregarAlumno = (person) => {
        const newAlumnos = alumnos.data.map((alumno) =>
            alumno.idcontrato != person.idcontrato ? alumno : { ...alumno, isMovido: 0, isDelete: 0 }
        );
        setAlumnos({ data: newAlumnos, allData: newAlumnos });
    };

    // Services
    const getInfoInicial = () => {
        services.getGruposDefault(
            (res) => {
                let idAreasUsuario = props.infoUsuario.areas.map((area) => area.idarea) || [];
                let gruposDefault = res.filter((grupo) => {
                    let usuarioTieneArea = false;
                    if (grupo.tipo_grupo == 'grupo') return true;
                    let idAreasCurso = grupo.areas.split(',');
                    idAreasCurso.forEach((idarea) => {
                        if (idAreasUsuario.includes(idarea)) usuarioTieneArea = true;
                    });
                    return usuarioTieneArea;
                });
                setGruposTrainer({ grupos: gruposDefault });
            },
            { areas: props?.infoUsuario?.areas || [] }
            // ,esTrainers: esVistaTrainers
        );
    };

    const agregarGrupoTrainer = () => {
        context.formulario.abrir({
            open: true,
            titulo: 'Nuevo grupo',
            width: 'xs',
            propsForm: { data: null },
            formulario: AgregarGrupoTrainer,
            aceptar: (dataForm) => {
                services.agregarGrupoTrainer(
                    (res) => {
                        setGruposTrainer({ grupos: res.grupos });
                        context.formulario.cerrar();
                    },
                    { trainer: data.row, form: dataForm }
                );
            }
        });
    };

    const getAlumnosGrupo = (dataSelected) => {
        if (!dataSelected?.idGrupo) return false;
        services.getAlumnosGrupo((res) => {
            const arrIdpersona = res.alumnos.map((dat) => dat.idcontrato);
            const arrPersonal = personalOriginal.filter((alumno) => !arrIdpersona.includes(alumno.idcontrato));

            setAlumnos({ data: res.alumnos, allData: res.alumnos });
            setBusqueda(res.alumnos);
            setPersonal(arrPersonal);
        }, dataSelected);
        return true;
    };

    const aceptar = () => {
        if (!dataForm.grupo) {
            return context.mensaje({ open: true, status: -1, value: 'Necesita seleccionar un curso.' });
        }
        const alumnosSend = alumnos.data.filter((alumno) => alumno.isMovido == 1);
        const dataSend = { trainer: data.row, alumnos: alumnosSend, form: dataForm, withLeader: data.withLeader };

        services.agregarColaborador((res) => {
            updatesetPersonal();
            cerrar();
        }, dataSend);

        return true;
    };

    const cargarFunciones = () => {
        getInfoInicial();
    };

    useEffect(() => {
        if (open) {
            setPersonal(data.personal);
            setPersonalOriginal(data.personal);
            cargarFunciones();
        }
    }, [open]);

    // Config
    const accionesTabla = [
        { label: 'Agregar colaborador a trainer', icon: <GroupAdd />, click: asignarAlumnoATrainer, dataSelected: true }
    ];

    return (
        <div>
            <Dialog
                open={open}
                maxWidth="lg"
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    {data.withLeader == 1 && (
                        <Typography variant="h4" gutterBottom component="div">
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {data?.row?.nombre_persona}
                                <School color="secondary" style={{ paddingBottom: '5px' }} />
                            </div>
                        </Typography>
                    )}
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Tabla
                                id="tablaColaborador"
                                rows={personal}
                                columns={headers(() => {}).colaboradores}
                                isSeleccionable
                                columnKey="idcontrato"
                                accionesTabla={accionesTabla}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <div className={classes.divGrupoAlumnos}>
                                <Typography variant="h5" gutterBottom component="div" display="flex" justifyContent="center">
                                    Curso
                                </Typography>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        value={dataForm?.grupo ? dataForm.grupo.label : ''}
                                        options={gruposTrainer?.grupos}
                                        onChange={(event, newValue) => {
                                            getAlumnosGrupo(newValue);
                                            setDataForm({ ...dataForm, grupo: newValue });
                                        }}
                                        sx={{ width: 250 }}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.label}
                                                {option.isLider ? <School color="secondary" style={{ paddingBottom: '5px' }} /> : null}
                                            </Box>
                                        )}
                                        renderInput={(params) => <TextField {...params} label="Curso" />}
                                    />
                                    {/* <Button
                                        variant="contained"
                                        sx={{ height: 50, paddingRight: '5px' }}
                                        startIcon={<Add />}
                                        onClick={agregarGrupoTrainer}
                                    /> */}
                                </Stack>
                                <br />
                                <Typography variant="h5" gutterBottom component="div" display="flex" justifyContent="center">
                                    Alumnos
                                </Typography>
                                <Toolbar className={clsx(classes.root, classes.highlight)}>
                                    <Input
                                        placeholder="Buscador"
                                        onChange={busquedaColaborador}
                                        inputProps={{ 'aria-label': 'search' }}
                                        fullWidth
                                    />
                                </Toolbar>
                                <Box sx={{ overflow: 'auto', width: '100%', height: 400, maxWidth: 360, bgcolor: 'background.paper' }}>
                                    <List dense>
                                        {alumnos?.data?.map((person) => (
                                            <ListItem>
                                                <ListItemIcon>
                                                    {person.isDelete == 1 ? (
                                                        <PersonRemove className={classes.remove} />
                                                    ) : (
                                                        <Person color="primary" />
                                                    )}
                                                </ListItemIcon>
                                                <ListItemText primary={person.nombre_persona} secondary={person.nom_puesto} />

                                                {person.isNuevo == 0 && (
                                                    <ListItemIcon>
                                                        <CloudDone color="blue" />
                                                    </ListItemIcon>
                                                )}

                                                {person.isDelete == 1 ? (
                                                    <IconButton className={classes.aceptar} onClick={() => reagregarAlumno(person)}>
                                                        <AddCircle />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton className={classes.remove} onClick={() => removerAlumno(person)}>
                                                        <RemoveCircle />
                                                    </IconButton>
                                                )}
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.cancelar} onClick={cerrar}>
                        Cancelar
                    </Button>
                    <Button className={classes.aceptar} onClick={aceptar}>
                        Agregar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AgregarColaborador;
