import { School } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const columns = (cambioEstatus) => ({
    personal: [
        { label: 'No. Empleado', name: 'num_empleado', filtrar: true },
        { label: 'Puesto', name: 'nom_puesto', filtrar: true },
        { label: 'Sede', name: 'nom_sede', filtrar: true },
        // { label: 'Nombre', name: 'nombre_persona', filtrar: true },
        {
            label: 'Nombre',
            name: 'nombre_persona',
            filtrar: true,
            type: (data) => {
                const res = data.nombre_persona.split('|');
                const nombrePersona = res[0];

                if (data.isTrainer == 0) return nombrePersona;

                return (
                    <div style={{ width: '100px', display: 'flex', justifyContent: 'center' }}>
                        <School color="secondary" />
                        {nombrePersona}
                    </div>
                );
            }
        },
        { label: 'Estatus', name: 'estatus_vacante', filtrar: true },
        // { label: 'Correo', name: 'email_empresarial', filtrar: true },
        { label: 'Grupo', name: 'nom_grupo', filtrar: true },
        // { label: 'Fecha ingreso', name: 'fecha_ingreso', type: { opcion: 'date', accion: 1 }, filtrar: true },
        // { label: 'Fecha contrato', name: 'fecha_contrato', type: { opcion: 'date', accion: 1 }, filtrar: true },
        { label: 'Primer conexión', name: 'primer_conexion', type: { opcion: 'date', accion: 1 }, filtrar: false },
        { label: 'Última conexión', name: 'ultima_conexion', type: { opcion: 'date', accion: 1 }, filtrar: false },
        // { label: 'Última conexión', name: 'ultima_conexion', type: { opcion: 'date', accion: 1 }, filtrar: false, hidden: true },
        { label: 'Días inactivo', name: 'difdias', filtrar: true },
        { label: 'Estatus', name: 'estatus_vacante', filtrar: true, hidden: true },
        { label: 'Bloqueado', name: 'nbloqueado', filtrar: true },
        { label: 'Fecha asignación', name: 'fAsignacion', type: { opcion: 'date', accion: 1 }, filtrar: false }
    ],
    colaboradores: [
        { label: 'No. Empleado', name: 'num_empleado', filtrar: true },
        { label: 'Puesto', name: 'nom_puesto', filtrar: true },
        { label: 'Sede', name: 'nom_sede', filtrar: true },
        // { label: 'Nombre', name: 'nombre_persona', filtrar: true },
        {
            label: 'Nombre',
            name: 'nombre_persona',
            filtrar: true,
            type: (data) => {
                const res = data.nombre_persona.split('|');
                const nombrePersona = res[0];

                if (data.isTrainer == 0) return nombrePersona;

                return (
                    <div style={{ width: '100px', display: 'flex', justifyContent: 'center' }}>
                        <School color="secondary" />
                        {nombrePersona}
                    </div>
                );
            }
        }
        // { label: 'Correo', name: 'email_empresarial', filtrar: true }
    ],
    sesion: [
        { label: '# Sesión', name: 'numSesion', filtrar: true },
        { label: 'Título', name: 'titulo', filtrar: true },
        { label: 'Duración', name: 'duracionSesion', filtrar: true },
        {
            label: 'Estatus',
            name: 'estatus',
            filtrar: false,
            type: { opcion: 'switch', accion: cambioEstatus }
        }
    ],
    actividad: [
        { label: 'Actividad', name: 'titulo', filtrar: true },
        {
            label: 'Estatus',
            name: 'estatus',
            filtrar: false,
            type: { opcion: 'switch', accion: cambioEstatus }
        }
    ],
    administrativo: [
        { label: 'No. Empleado', name: 'num_empleado', filtrar: true },
        {
            label: 'Nombre',
            name: 'nombre_persona',
            filtrar: true,
            type: (data) => {
                const res = data.nombre_persona.split('|');
                const nombrePersona = res[0];

                if (data.isTrainer == 0) return nombrePersona;

                return (
                    <div style={{ width: '100px', display: 'flex', justifyContent: 'center' }}>
                        <School color="secondary" />
                        {nombrePersona}
                    </div>
                );
            }
        },
        { label: 'Estatus', name: 'estatus_vacante', filtrar: true },
        { label: 'Tipo', name: 'tipo_puesto', filtrar: true },
        { label: 'Departamento', name: 'nom_area', filtrar: true },
        { label: 'Area', name: 'nom_subarea', filtrar: true },
        { label: 'Puesto', name: 'nom_puesto', filtrar: true },
        { label: 'Correo empres', name: 'email_empresarial', filtrar: true },
        { label: 'Correo perso', name: 'email_personal', filtrar: true },
        { label: 'Tel 1', name: 'telefono1', filtrar: true },
        { label: 'Tel 2', name: 'telefono2', filtrar: true }
    ]
});

export default columns;
