import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, colors, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { CloudUpload, CloudDownload } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import Services from '../Components/services';

const useStyles = makeStyles((theme) => ({
    helperTextError: {
        color: colors.red[500],
        marginLeft: '14px',
        marginRight: '14px',
        marginTop: '4px',
        fontSize: '0.75rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    helperText: {
        color: 'black',
        marginLeft: '0px',
        marginRight: '14px',
        marginTop: '4px',
        fontSize: '1rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    helperText2: {
        color: colors.grey[500],
        marginLeft: '14px',
        marginRight: '14px',
        marginTop: '4px',
        fontSize: '0.75rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    }
}));

const FormEvidencia = (props) => {
    const classes = useStyles();
    const { data } = props;
    const services = Services();
    const [fileSelected, setFileSelected] = useState({
        selected: false,
        name: '',
        formatoValido: 'No valido',
        asDefault: false
    });
    const [dataDefault, setDataDefault] = useState(null);
    const {
        register,
        watch,
        formState: { errors },
        control,
        setValue,
        getValues
    } = useFormContext();

    const validarTamanio = (tamanio) => {
        if (!tamanio) return false;
        return tamanio / 1024 / 1024 < 25;
    };

    const extensionesNoValidas = [
        'application/x-msdownload',
        'application/javascript',
        'text/javascript',
        'application/x-msdos-program',
        'application/x-sh',
        'text/x-sh',
        'application/octet-stream',
        ''
    ];

    const getEvidencia = async () => {
        await services.getRespuestaActividad(
            (res) => {
                if (res.comentario) {
                    setFileSelected({ ...fileSelected, asDefault: true });
                    setDataDefault(res);
                    setValue('esUpdate', true);
                } else setValue('esUpdate', false);
            },
            { idDetalleActividad: data.idDetalleActividad, idColaborador: data.idColaborador }
        );
    };

    const cargarFunciones = () => {
        getEvidencia();
    };

    useEffect(() => {
        cargarFunciones();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item lg={12} md={12} xs={12}>
                <Button component="label" variant="contained" tabIndex={-1} startIcon={<CloudUpload />} color="secondary">
                    {fileSelected.asDefault ? 'Cambiar' : 'Subir'} archivo evidencia *
                    <TextField
                        style={{ display: 'none' }}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => {
                            console.log('file', e.target.files);
                            e.target.files[0]
                                ? setFileSelected({
                                      ...fileSelected,
                                      selected: true,
                                      name: e.target.files[0].name
                                  })
                                : setFileSelected({
                                      ...fileSelected,
                                      selected: false,
                                      name: ''
                                  });
                        }}
                        type="file"
                        onClick={(event, v) => {
                            event.target.value = null;
                        }}
                        {...register('archivo', {
                            required: { value: fileSelected.asDefault ? false : true, message: 'Campo requerido' },
                            validate: (value) =>
                                fileSelected.asDefault
                                    ? true
                                    : !extensionesNoValidas.includes(value[0]?.type) || 'Extensión o tamaño no válido'
                        })}
                        error={errors?.archivo?.message}
                    />
                </Button>
                <br></br>
                <br></br>
                <helperText className={fileSelected.selected && !errors?.archivo ? classes.helperText2 : classes.helperTextError}>
                    {fileSelected.selected && !errors?.archivo && '(Seleccionado: ' + fileSelected.name + ' )'}
                    {errors?.archivo?.message}
                </helperText>
            </Grid>
            <hr></hr>
            {fileSelected.asDefault && dataDefault && (
                <>
                    <Grid item lg={12} md={12} xs={12}>
                        <Button
                            id="btn-evid"
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudDownload />}
                            color="primary"
                            onClick={() => window.open(dataDefault?.comentario, '_blank', 'noreferrer')}
                        >
                            Ver archivo evidencia a
                        </Button>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        <Typography style={{ fontWeight: 'bold' }}>Subido el: </Typography>
                        <Typography>{dataDefault.fechaCreacion}</Typography>
                        <br></br>
                        {/* <Typography style={{ fontWeight: 'bold' }}>Subido por: </Typography>
                        <Typography>{dataDefault.ncrea}</Typography> */}
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default FormEvidencia;
