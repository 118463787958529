import LayautContext from 'Context/ContextLayaut';
import { useContext } from 'react';

const ServiciosPerfil = () => {
    const context = useContext(LayautContext);

    return {
        getDatosPerfil: context.llamarServidor('Usuario/getDatosPerfil'),
        cambiarMiContrasena: context.llamarServidorRespuesta('Usuario/cambiarMiContrasena')
    };
};
export default ServiciosPerfil;
